<template>
  <div class="page-wrap text-center">
    <div class="session-form-hold">
      <base-card class="rounded-xl">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
          class="rounded-xl"
        >
        </v-progress-linear>

        <div v-if="loading" class="pa-4">
          <div class="d-flex align-center py-4">
            <v-img
              v-if="$vuetify.theme.dark"
              width="200"
              height="60"
              contain
              src="@/assets/images/logo/logo-light.png"
              alt=""
            />
            <v-img
              v-else
              width="200"
              height="60"
              contain
              src="@/assets/images/logo/logo-dark.png"
              alt=""
            />
          </div>
          <v-alert type="info" text>
            <p class="mb-0">Verificando permissões para gravação...</p>
          </v-alert>
        </div>
        <div v-else-if="error" class="pa-5">
          <v-icon size="70" color="error">mdi-close-circle</v-icon>
          <h4 class="mb-0">{{ errorMessage }}</h4>
          <p>
            Houve um erro ao validar sua permissão para gravar. Se o erro
            persistir, entre em contato com o suporte.
          </p>
        </div>
        <div v-else class="pa-5">
          <v-icon size="100" color="error">mdi-record</v-icon>
          <h4>Gravação de Sessão</h4>
          <p>
            Estamos comprometidos com a proteção de sua privacidade. As
            informações serão gravadas somente durante a sessão atual, não
            capturando nada antes ou depois da sua visita ao site.
          </p>
          <h6 class="mb-0">O que será gravado</h6>
          <v-list class="text-left pt-0 mb-2">
            <v-list-item v-for="(item, index) in recordItems" :key="index">
              <v-list-item-icon class="my-auto">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <h6 class="mb-0">O que <strong>não</strong> será gravado</h6>
          <v-list class="text-left pt-0">
            <v-list-item v-for="(item, index) in notRecordItems" :key="index">
              <v-list-item-icon class="my-auto">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="py-1">
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-checkbox v-model="checkbox" label="Aceitar termos e condições" />
          <v-btn
            block
            color="primary"
            :disabled="!checkbox || loadingRecording"
            @click="startRecording"
            :loading="loadingRecording"
          >
            {{
              loadingRecording ? "Iniciando gravação..." : "Iniciar gravação"
            }}
          </v-btn>
        </div>
      </base-card>
      <v-btn class="mt-4" dark small text to="/">
        <v-icon left small>mdi-arrow-left</v-icon>
        Voltar para o site
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import recorderService from "@/services/support/recorder";

export default {
  components: {},
  name: "Record",
  metaInfo: {
    title: "Aceite de termos e condições",
  },
  data() {
    return {
      loading: true,
      loadingRecording: false,
      checkbox: false,
      error: false,
      errorMessage: "",
      session: "",
      recordItems: [
        {
          icon: "mdi-web",
          title: "Informações sobre a navegação",
        },
        {
          icon: "mdi-timer",
          title: "Informações sobre a sessão",
        },
        {
          icon: "mdi-alert-circle-outline",
          title: "Erros relacionados ao sistema",
        },
      ],
      notRecordItems: [
        {
          icon: "mdi-keyboard-off-outline",
          title: "Teclas digitadas pelo usuário",
        },
        {
          icon: "mdi-account-off-outline",
          title: "Informações pessoais",
        },
        {
          icon: "mdi-form-textbox-password",
          title: "Dados inseridos em formulários",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["changeThemeDarkMode"]),
    redirect() {
      let to = this.$route.query.redirect || "/app";
      this.$router.push(to);
    },
    async validateSession(sessionId) {
      try {
        const response = await recorderService.getSession(sessionId);
        return response.success;
      } catch (error) {
        console.error("Erro ao validar sessão:", error);
        this.errorMessage = error.message || "Erro ao validar sessão";
        return false;
      }
    },
    async startRecording() {
      this.loadingRecording = true;
      try {
        this.$root.$emit("recorder-start", this.session);

        await new Promise((resolve) => setTimeout(resolve, 1000));

        this.redirect();
      } catch (error) {
        console.error("Erro ao iniciar a gravação:", error);
        this.error = true;
        this.errorMessage =
          "Erro ao iniciar a gravação. Por favor, tente novamente.";
      } finally {
        this.loadingRecording = false;
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  async mounted() {
    console.log("Record component mounted");
    console.log("Session:", this.$route.query.session);

    this.session = this.$route.query.session;

    try {
      const isSessionValid = await this.validateSession(this.session);
      if (!isSessionValid) {
        this.error = true;
        this.errorMessage = "Sessão inválida ou não encontrada";
      }
    } catch (error) {
      this.error = true;
      this.errorMessage = "Erro ao verificar sessão";
    } finally {
      this.loading = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 50%) 0%,
      rgba(0, 0, 0, 90%) 90vw
    ),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
